import { useState } from "react";
import Environmental from "./Environmental/Environmental";
import Maintenance from "./Maintenance/Maintenance";
import Ergonomic from "./Ergonomic/Ergonomic";
import Zones from "./Zones/Zones";
import Forklifts from "./Forklifts/Forklifts";
import Employees from "./Employees/Employees";
interface DataTabProps {
  selectedDataTab: string | null;
  highlighting: boolean;
  dateRange: [Date | undefined, Date | undefined];
  setDateRange: (dateRange: [Date | undefined, Date | undefined]) => void;
  currentArea: string;
  currentSubArea: string | null;
  heatMapEnabled: boolean
  setHeatMapEnabled: Function
  selectedTab: string | null
}

function DataTab({
  selectedDataTab,
  highlighting,
  dateRange,
  setDateRange,
  currentArea,
  currentSubArea,
  heatMapEnabled,
  setHeatMapEnabled,
  selectedTab
}: DataTabProps) {
  if (selectedDataTab === "environmental") {
    return (
      <Environmental
        highlighting={highlighting}
        dateRange={dateRange}
        setDateRange={setDateRange}
        currentArea={currentArea}
        currentSubArea={currentSubArea}
      />
    );
  }

  if (selectedDataTab === "ergonomics") {
    return (
      <Ergonomic
        highlighting={highlighting}
        currentArea={currentArea}
        currentSubArea={currentSubArea}
      />
    );
  }

  if (selectedDataTab === "maintenance") {
    return (
      <Maintenance
        highlighting={highlighting}
        dateRange={dateRange}
        currentArea={currentArea}
        currentSubArea={currentSubArea}
      />
    );
  }

  if (selectedTab === "zones") {

    return (
      <Zones highlighting={highlighting}
          dateRange={dateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea} />
    )
  }

  if (selectedTab === "employees") {
    return (
      <Employees
        highlighting={highlighting}
        dateRange={dateRange}
        currentArea={currentArea}
        currentSubArea={currentSubArea}
        heatMapEnabled = {heatMapEnabled}
        setHeatMapEnabled = {setHeatMapEnabled}
      />
    );
  }

  return null;
}

export default DataTab;
