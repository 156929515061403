import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {
  FormControlLabel,
  Switch,
} from "@mui/material";
import NotificationsTab from "./rightBarSubItems/notifications/NotificationsTab";
import DataTab from "./rightBarSubItems/data/DataTab";
import DataSelections from "./DataSelections";
import DateRangePickerComponent from "./rightBarSubItems/DateRangePickerComponent";
import {  startOfMonth, endOfMonth } from "date-fns";

const drawerWidth = 770;

interface PersistentDrawerRightProps {
  open: boolean;
  handleDrawerClose: () => void;
  currentSubArea: string | null;
  setCurrentSubArea: Function
  currentArea: string;
  heatMapEnabled: boolean
  setHeatMapEnabled: Function
}

export default function PersistentDrawerRight({
  open,
  handleDrawerClose,
  currentSubArea,
  setCurrentSubArea,
  currentArea,
  heatMapEnabled,
  setHeatMapEnabled
}: PersistentDrawerRightProps) {
  const [selectedTab, setSelectedTab] = useState<string | null>(
    "data"
  );

  const [selectedDataTab, setSelectedDataTab] = useState<string | null>(
    "zones"
  );

  useEffect(() => {
    console.log("selectedTab:",selectedTab)
    console.log("selectedDataTab:", selectedDataTab)
  }, [selectedTab, selectedDataTab])

  const [highlighting, setHighlighting] = useState(true);

  const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>(() => {
    // Default to the current month
    const start = startOfMonth(new Date()); 
    const end = endOfMonth(new Date()); 
    return [start, end];
  });

  const [areaFilter, setAreaFilter] = useState<boolean>(true);

  const handleAreaToggleChange = () => {
    setAreaFilter(!areaFilter)
    if (currentSubArea) {
      setCurrentSubArea(null)
    }
  }


  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Divider />
      <List disablePadding>
        <Divider />
        <DataSelections
          selectedDataTab={selectedDataTab}
          setSelectedDataTab={setSelectedDataTab}
          handleDrawerClose = {handleDrawerClose}
          selectedTab = {selectedTab}
          setSelectedTab = {setSelectedTab}
          
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
          marginTop="1%"
        >
          <Box display="flex" flexDirection="row">
             <DateRangePickerComponent
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
          </Box>
          <FormControlLabel
              control={
                <Switch
                  checked={highlighting}
                  onChange={() => setHighlighting(!highlighting)}
                />
              }
              label="Highlighting"
            />

<FormControlLabel
              control={
                <Switch
                checked={!!currentSubArea} 
                  onChange={handleAreaToggleChange}
                />
              }
              label="Filter by Sub Area"
            />
        </Box>
    
          <DataTab  selectedDataTab={selectedDataTab}
          highlighting={highlighting}
          dateRange={dateRange}
          setDateRange={setDateRange}
          currentArea={currentArea}
          currentSubArea={currentSubArea}
          heatMapEnabled = {heatMapEnabled}
          setHeatMapEnabled={setHeatMapEnabled}
          selectedTab = {selectedTab}
          />
      </List>
    </Drawer>
  );
}
