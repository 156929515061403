import { Box, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";

const drawerWidth = 770;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  position: "relative",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));


interface DataSelectionsProps {
  selectedDataTab: string | null;
  setSelectedDataTab: (tab: string | null) => void;
  handleDrawerClose: () => void
  selectedTab: string | null
  setSelectedTab: (tab:string | null) => void
}

function DataSelections({
  selectedDataTab,
  setSelectedDataTab,
  handleDrawerClose,
  selectedTab,
  setSelectedTab
}: DataSelectionsProps) {
  //   const [selectedDataTab, setSelectedDataTab] = useState<string | null>(
  //     "environmental"
  //   );

  const handleTabSelect = (
    event: React.MouseEvent<HTMLElement>,
    newTab: string | null
  ) => {
    setSelectedTab(newTab);
  };

  const theme = useTheme();

  return (
    <ToggleButtonGroup
      value={selectedTab}
      exclusive
      onChange={handleTabSelect}
      aria-label="text alignment"
      sx={{ width: "100%", height: "50px" }}
    >
      {/* <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="environmental"
        aria-label="environmental"
        disabled
      >
        <h4>Environmental</h4>
        <Box
          sx={{
            position: "absolute",
            top: -10,
            right: 0,
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "red",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          5
        </Box>
      </ToggleButton> */}
      {/* <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="ergonomics"
        aria-label="ergonomics"
        disabled
      >
        <h4>Ergonomics</h4>
      </ToggleButton> */}
      {/* <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="maintenance"
        aria-label="maintenance"
        disabled
      >
        <h4>Maintenance</h4>
        <Box
          sx={{
            position: "absolute",
            top: -10,
            right: 0,
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: "red",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          11
        </Box>
      </ToggleButton> */}


      <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="zones"
        aria-label="zones"
      >
        <h4>Zones</h4>
      </ToggleButton>
      {/* <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="forklifts"
        aria-label="forklifts"
      >
        <h4>Devices</h4>
      </ToggleButton> */}
      <ToggleButton
        sx={{ width: "50%", textTransform: "none" }}
        value="employees"
        aria-label="employees"
      >
        <h4>Employees</h4>
      </ToggleButton>

      <DrawerHeader>
            <IconButton
              onClick={handleDrawerClose}
            >
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
    </ToggleButtonGroup>
  );
}

export default DataSelections;
